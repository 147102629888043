import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import App from './App';
import {RootTheme} from "./styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

document.body.style.margin = '0';
document.body.style.backgroundColor = '#f5f5f5';

ReactDOM.render(
    <MuiThemeProvider theme={RootTheme}>
        <App/>
    </MuiThemeProvider>,
    document.getElementById('root'));

import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import teal from "@material-ui/core/colors/teal";

export const RootTheme = createMuiTheme({
    palette: {
        primary: teal,
        secondary: {
            main: '#DC2C01',
        }
    },
    typography: {
        useNextVariants: true,
    },
});

export const AppStyle = (theme) => ({
    paper: {
        position: 'fixed',
        zIndex: '-1',
        width: '100%',
        height: '35%',
        backgroundColor: 'rgba(70, 70, 70, 0.7)',
        transition: 'background 0.75s ease',
    },
    paperContent: {
        paddingTop: theme.spacing.unit * 8,
        paddingBottom: theme.spacing.unit * 40,
    },
    title: {
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: '50px',
        lineHeight: '1.35',
        letterSpacing: '-.02em',
        margin: '72px auto 24px',
        fontWeight: '400',
        padding: '0',
    },
});

export const CardStyle = (theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        maxWidth: '550px',
        margin: '32px auto',
        borderRadius: '3px',
    },
    headerImage: {
        width: '100%',
        height: '100%',
    },
    contentHeader: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingBottom: theme.spacing.unit * 2,
    },
    launcherImage: {
        width: '48px',
        height: '48px',
        borderRadius: '12%',
        verticalAlign: 'middle',
    },
    backgroundGray: {
        background: 'lightgray',
    },
    title: {
        marginLeft: theme.spacing.unit * 2,
        verticalAlign: 'middle',
        display: 'inline',
    },
    desc: {
        margin: '0',
        letterSpacing: '.02em',
        lineHeight: '22px',
        fontSize: '16px',
        fontWeight: '400',
        color: 'rgba(0, 0, 0, .54)',
    },
    // custom card styles
    uberspace: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        maxWidth: '350px',
        margin: '64px auto 32px',
        borderRadius: '3px',
    },
    uberspaceHeaderImgWrapper: {
        background: 'black',
        display: 'flex',
    },
    uberspaceHeaderImg: {
        width: '150px',
        height: '80px',
        margin: '10% auto',
        display: 'flex',
    },
    invisible: {
        display: 'none',
    },
});

export const FooterStyle = (theme) => ({
    footer: {
        backgroundColor: '#555555',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: theme.spacing.unit * 11,
    },
    footerWrapper: {
        padding: '16px',
        width: '80%',
        maxWidth: '550px',
        margin: '0 auto 0',
        display: 'flex',
    },
    footerLinks: {
        margin: '0 2% 0 auto',
    },
    footerImages: {
        margin: '0 auto 0 2%',
        display: 'grid',
    },
    footerImage: {
        marginTop: theme.spacing.unit / 4,
        marginBottom: theme.spacing.unit / 4,
        marginRight: theme.spacing.unit / 2,
        marginLeft: theme.spacing.unit / 2,
        width: theme.spacing.unit * 3,
        height: theme.spacing.unit * 3,
    },
    footerElement: {
        textAlign: 'center',
        padding: '4px',
        color: 'white',
    },
    footerPrivacy: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography/Typography";
import MyCard from "./component/presentational/MyCard";
import Footer from "./component/presentational/Footer";
import {CardAboutMe, CardUberspace, HeaderConst, ProjectsCards, StringConst} from "./constants";
import {AppStyle} from "./styles";
import {changeColor} from "./anim/header";
import {loadImages} from "./anim/imageLoader";

window.onscroll = changeColor;
window.onload = loadImages;

const createCard = info => {
    return (
        <MyCard
            id={info.ID}
            key={info.ID}
            customClass={info.CUSTOM_CLASS}
            headerImageWrapperClass={info.HEADER_IMG_WRAPPER_STYLE}
            headerImage={info.HEADER_IMG}
            headerImageClass={info.HEADER_IMG_STYLE}
            contentHeaderClass={info.CONTENT_HEADER_STYLE}
            launcherImage={info.LAUNCHER_IMG}
            launcherImageClass={info.LAUNCHER_IMG_STYLE}
            title={info.TITLE}
            desc1={info.DESC1}
            desc2={info.DESC2}
            desc3={info.DESC3}
            button1={info.BUTTON1}
            action1={info.ACTION1}
            button2={info.BUTTON2}
            action2={info.ACTION2}
        />
    )
};

function App(props) {
    const {classes} = props;

    const cards = ProjectsCards.map(c => createCard(c));

    return (
        <div>
            <Paper id={HeaderConst.ID} square={true} className={classes.paper}/>
            <div className={classes.paperContent}>
                {createCard(CardAboutMe)}
                <Typography className={classes.title} variant="h1">
                    {StringConst.TITLE}
                </Typography>
                {cards}
                {createCard(CardUberspace)}
            </div>
            <Footer/>
        </div>
    );
}

export default withStyles(AppStyle)(App);

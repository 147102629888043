import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import {FooterStyle} from "../../styles";
import twitterImg from "../../img/footer/twitter.png";
import githubImg from "../../img/footer/github.png";
import googleplayImg from "../../img/footer/googleplay.png";
import emailImg from "../../img/footer/email.png";
import stackoverflowImg from "../../img/footer/stackoverflow.png";
import spotifyImg from "../../img/footer/spotify.png";
import {FooterConst} from "../../constants";

const Footer = (props) => {
    const {classes} = props;

    return (
        <footer className={classes.footer}>
            <div className={classes.footerWrapper}>
                <div className={classes.footerLinks}>
                    <Typography className={classes.footerElement}>
                        © Copyright {new Date().getFullYear()}
                    </Typography>
                    <Typography
                        className={[classes.footerElement, classes.footerPrivacy].join(' ')}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://www.oemel09.de/privacy.html"}>
                        Privacy and terms
                    </Typography>
                </div>
                <div className={classes.footerImages}>
                    <div>
                        <a href={FooterConst.links.TWITTER} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={twitterImg}
                                 alt={FooterConst.imageAltText.TWITTER}/>
                        </a>
                        <a href={FooterConst.links.GITHUB} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={githubImg} alt={FooterConst.imageAltText.GITHUB}/>
                        </a>
                        <a href={FooterConst.links.GOOGLE_PLAY} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={googleplayImg}
                                 alt={FooterConst.imageAltText.GOOGLE_PLAY}/>
                        </a>
                    </div>
                    <div>
                        <a href={FooterConst.links.EMAIL} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={emailImg} alt={FooterConst.imageAltText.EMAIL}/>
                        </a>
                        <a href={FooterConst.links.STACKOVERFLOW} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={stackoverflowImg}
                                 alt={FooterConst.imageAltText.STACKOVERFLOW}/>
                        </a>
                        <a href={FooterConst.links.SPOTIFY} target="_blank" rel="noopener noreferrer">
                            <img className={classes.footerImage} src={spotifyImg}
                                 alt={FooterConst.imageAltText.SPOTIFY}/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(FooterStyle)(Footer);

export const HeaderConst = {
  ID: 'page_header',
};

export const Colors = {
    cardAboutMe: "rgba(70, 70, 70, 0.7)",
    cardMessAnchor: "#4cb050",
    cardSqlInjections: "rgba(0,0,0,0.7)",
    cardSmashIT: "rgba(255, 127, 0, 0.6)",
    cardWayTooFast: "rgb(105,224,0)",
    cardDailyPower: "rgba(103, 58, 183, 0.7)",
    cardSeriously: "rgba(31, 45, 75, 0.75)",
    cardCustomeals: "rgba(32, 150, 39, 0.7)",
    cardSimpleFlashlight: "rgba(255, 234, 0, 0.45)",
    cardUberspace: "rgba(184, 27, 33, 0.75)",
    defaultColor: "rgba(70, 70, 70, 0.7)",
};

export const StringConst = {
    TITLE: 'my latest work',
};

export const CardAboutMe = {
    ID: "cardAboutMe",
    HEADER_IMG: require("./img/oemel09/header.png"),
    LAUNCHER_IMG: require("./img/oemel09/developer_icon.png"),
    TITLE: "OEMEL09",
    DESC1: "Computer scientist.",
    DESC2: "Passionate software developer.",
    DESC3: "From Germany.",
    BUTTON1: "My apps",
    ACTION1: "https://play.google.com/store/apps/developer?id=OEMEL09",
    BUTTON2: "Say hello",
    ACTION2: "mailto:hallo@oemel09.de?Subject=Say hello",
};

export const CardSqlInjections = {
    ID: "cardSqlInjections",
    HEADER_IMG: require("./img/sql-injections/header.png"),
    LAUNCHER_IMG: require("./img/sql-injections/ic_launcher.png"),
    TITLE: "SQL Injections",
    DESC1: "Vulnerable demo application.",
    DESC2: "Raise awareness of SQL injections.",
    DESC3: "Always use prepared statements.",
    BUTTON1: "Github",
    ACTION1: "https://github.com/oemel09/SQL-Injections",
};

export const CardSmashIT = {
    ID: "cardSmashIT",
    HEADER_IMG: require("./img/smashit/header.png"),
    LAUNCHER_IMG: require("./img/smashit/ic_launcher.png"),
    TITLE: "SmashIT!",
    DESC1: "Challenge your friends on an iPad.",
    DESC2: "Software project at university.",
    DESC3: "Developed with a team of ten people.",
    BUTTON1: "Gitlab",
    ACTION1: "https://gitlab.com/ip2/smashit",
};

export const CardMessAnchor = {
    ID: "cardMessAnchor",
    HEADER_IMG: require("./img/messanchor/header.jpg"),
    LAUNCHER_IMG: require("./img/messanchor/ic_launcher.png"),
    TITLE: "MessAnchor",
    DESC1: "Choose the right messenger.",
    DESC2: "For every contact.",
    DESC3: "Automatically.",
    BUTTON1: "Download",
    ACTION1: "https://play.google.com/store/apps/details?id=de.oemel09.messanchor",
    BUTTON2: "GitHub",
    ACTION2: "https://github.com/oemel09/messanchor",
};


export const CardWayTooFast = {
    ID: "cardWayTooFast",
    HEADER_IMG: require("./img/waytoofast/header.jpg"),
    LAUNCHER_IMG: require("./img/waytoofast/ic_launcher.png"),
    TITLE: "#waytoofast",
    DESC1: "Experience information overload.",
    DESC2: "Don't we do that everyday?",
    DESC3: "Choose wisely.",
    BUTTON1: "Discover",
    ACTION1: "https://waytoofast.oemel09.de/",
    BUTTON2: "GitHub",
    ACTION2: "https://github.com/oemel09/waytoofast",
};

export const CardDailyPower = {
    ID: "cardDailyPower",
    HEADER_IMG: require("./img/dailypower/header.png"),
    LAUNCHER_IMG: require("./img/dailypower/ic_launcher.png"),
    TITLE: "Daily Power (Web)",
    DESC1: "Get a daily bible verse onto your phone!",
    DESC2: "Per notification or via a widget.",
    DESC3: "Verses are also accessible on a web page.",
    BUTTON1: "Download app",
    ACTION1: "https://play.google.com/store/apps/details?id=de.oemel09.dailypower",
    BUTTON2: "Web",
    ACTION2: "https://dailypower.oemel09.de/",
};

export const CardSeriously = {
    ID: "cardSeriously",
    HEADER_IMG: require("./img/seriously/header.png"),
    LAUNCHER_IMG: require("./img/seriously/ic_launcher.png"),
    TITLE: "Seriously",
    DESC1: "Little guessing game.",
    DESC2: "Play with up to 3 friends! On one device or via wifi-direct.",
    DESC3: "Who knows the others best will win!",
    BUTTON1: "Download app",
    ACTION1: "https://play.google.com/store/apps/details?id=de.oemel09.seriously",
    BUTTON2: "Designed by",
    ACTION2: "https://heess.me/",
};

export const CardCustomeals = {
    ID: "cardCustomeals",
    HEADER_IMG: require("./img/customeals/header.png"),
    HEADER_IMG_STYLE: 'backgroundGray',
    LAUNCHER_IMG: require("./img/customeals/ic_launcher.ico"),
    TITLE: "Customeals",
    DESC1: "Customize your meal!",
    DESC2: "Internet programming project at university.",
    DESC3: "Developed with 2 friends.",
    BUTTON1: "Explore",
    ACTION1: "https://customeals.oemel09.de",
};

export const CardSimpleFlashlight = {
    ID: "cardSimpleFlashlight",
    HEADER_IMG: require("./img/simpleflashlight/header.png"),
    LAUNCHER_IMG: require("./img/simpleflashlight/ic_launcher.png"),
    TITLE: "Simple flashlight",
    DESC1: "Easiest way to turn on your flash!",
    DESC2: "Accessible via a widget.",
    DESC3: "Also possible to light up just the screen.",
    BUTTON1: "Download app",
    ACTION1: "https://play.google.com/store/apps/details?id=de.oemel09.taschenlampe",
};

export const CardUberspace = {
    ID: "cardUberspace",
    CUSTOM_CLASS: 'uberspace',
    HEADER_IMG_WRAPPER_STYLE: 'uberspaceHeaderImgWrapper',
    HEADER_IMG: require("./img/uberspace/header.png"),
    HEADER_IMG_STYLE: 'uberspaceHeaderImg',
    CONTENT_HEADER_STYLE: 'invisible',
    TITLE: "Uberspace",
    DESC1: "This website is hostet there!",
    DESC2: "Incredible support.",
    DESC3: "Thousands of possibilities.",
    BUTTON1: "Get started",
    ACTION1: "https://uberspace.de/",
};

export const ProjectsCards = [
    CardMessAnchor,
    CardWayTooFast,
    CardSqlInjections,
    CardDailyPower,
    CardSmashIT,
    CardSeriously,
    CardCustomeals,
    CardSimpleFlashlight,
];

export const AllCards = [
    CardAboutMe,
    CardMessAnchor,
    CardWayTooFast,
    CardSqlInjections,
    CardDailyPower,
    CardSmashIT,
    CardSeriously,
    CardCustomeals,
    CardSimpleFlashlight,
    CardUberspace,
];

export const FooterConst = {
    links: {
        TWITTER: 'https://twitter.com/oemel09/',
        GITHUB: 'https://github.com/oemel09/',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/dev?id=6053418656861582677',
        EMAIL: 'mailto:hallo@oemel09.de',
        STACKOVERFLOW: 'https://stackoverflow.com/users/10827064/oemel09',
        SPOTIFY: 'https://open.spotify.com/user/oemel09',
    },
    imageAltText: {
        TWITTER: 'twitter',
        GITHUB: 'github',
        GOOGLE_PLAY: 'google play',
        EMAIL: 'email me',
        STACKOVERFLOW: 'stackoverflow',
        SPOTIFY: 'spotify',
    },
};

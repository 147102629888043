import {
    AllCards,
    Colors,
    HeaderConst,
} from "../constants";

export const changeColor = function () {
    const header = document.getElementById(HeaderConst.ID);
    const cards = AllCards.map(c => document.getElementById(c.ID));
    const bottoms = cards.map(c => c.getBoundingClientRect().bottom);

    let color;
    let headerHeight = header.clientHeight;

    for(let i = 0; i < bottoms.length; i++) {
        if(bottoms[i] > headerHeight) {
            color = Colors[cards[i].id];
            break;
        }
        color = Colors.defaultColor;
    }
    header.style.background = color;
};

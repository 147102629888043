import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card/Card";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import {CardStyle} from "../../styles";

const MyCard = (props) => {
    const {
        id,
        classes, customClass, headerImageWrapperClass,
        headerImage, headerImageClass,
        contentHeaderClass,
        launcherImage, launcherImageClass,
        title, desc1, desc2, desc3,
        button1, action1, button2, action2,
    } = props;

    let cardStyle = classes.card;
    if (customClass) {
        cardStyle = classes[customClass];
    }
    let headerImageWrapperStyle = null;
    if (headerImageWrapperClass) {
        headerImageWrapperStyle = classes[headerImageWrapperClass];
    }

    const headerImageStyle = [classes.headerImage];
    if (headerImageClass) {
        headerImageStyle.push(classes[headerImageClass]);
    }
    const contentHeaderStyle = [classes.contentHeader];
    if (contentHeaderClass) {
        contentHeaderStyle.push(classes[contentHeaderClass]);
    }
    const launcherImageStyle = [classes.launcherImage];
    if (launcherImageClass) {
        launcherImageStyle.push(classes[launcherImageClass]);
    }

    return (
        <Card id={id} className={cardStyle} raised>
            <CardActionArea>
                <div className={headerImageWrapperStyle}>
                    <img
                        className={[headerImageStyle.join(' ')]}
                        src={require("../../img/dummy.png")}
                        data-src={headerImage}
                        alt=""
                    />
                </div>
                <CardContent>
                    <div className={contentHeaderStyle.join(' ')}>
                        <img
                            className={launcherImageStyle.join(' ')}
                            src={require("../../img/dummy.png")}
                            data-src={launcherImage}
                            alt=""
                        />
                        <Typography className={classes.title} variant="h5">
                            {title}
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.desc} variant="h6">
                            {desc1}
                        </Typography>
                        <Typography className={classes.desc} variant="h6">
                            {desc2}
                        </Typography>
                        <Typography className={classes.desc} variant="h6">
                            {desc3}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button color="secondary" href={action1} target="_blank">
                    {button1}
                </Button>
                {
                    (button2 !== null)
                        ? (
                            <Button color="secondary" href={action2} target="_blank">
                                {button2}
                            </Button>
                        ) : (
                            null
                        )
                }
            </CardActions>
        </Card>
    );
};

MyCard.defaultProps = {
    customClass: null,
    headerImageWrapperClass: null,
    headerImageClass: null,
    contentHeaderClass: null,
    launcherImageClass: null,
    button2: null,
    action2: null,
};

MyCard.propTypes = {
    id: PropTypes.string.isRequired,
    classes: PropTypes.any.isRequired,
    customClass: PropTypes.string,
    headerImageWrapperClass: PropTypes.string,
    headerImage: PropTypes.string.isRequired,
    contentHeaderClass: PropTypes.string,
    launcherImage: PropTypes.string,
    headerImageClass: PropTypes.string,
    launcherImageClass: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc1: PropTypes.string.isRequired,
    desc2: PropTypes.string.isRequired,
    desc3: PropTypes.string.isRequired,
    button1: PropTypes.string.isRequired,
    action1: PropTypes.string.isRequired,
    button2: PropTypes.string,
    action2: PropTypes.string,
};

export default withStyles(CardStyle)(MyCard);
